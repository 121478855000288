<template>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">Showing {{ pageMeta.from }} to {{ pageMeta.to }} of {{ pageMeta.of }} entries</span>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          :value="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="$emit('change', $event)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BPagination,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },
  model: {
    prop: 'currentPage',
    event: 'change',
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 20,
    },
  },
  setup(props) {
    const pageMeta = computed(() => ({
      from: props.totalRows > 0 ? props.perPage * (props.currentPage - 1) + 1 : 0,
      to: props.totalRows > props.perPage * props.currentPage ? props.perPage * props.currentPage : props.totalRows,
      of: props.totalRows,
    }))

    return {
      pageMeta,
    }
  },
}
</script>
