import {
  reactive,
  computed,
} from '@vue/composition-api'

import state from './state'

import {
  indexing,
} from '@/utils/value'

const items = computed(() => {
  const admByIdx = indexing(state.admList, 'idx')
  const advByIdx = indexing(state.advList, 'idx')
  return state.infRecommendJobList.map(job => {
    if (job.member_type === 'ADM') {
      const adm = admByIdx[job.member_idx]
      return {
        ...job,
        memberName: adm?.name,
        memberEmail: adm?.email,
      }
    }
    if (job.member_type === 'ADV') {
      const adv = advByIdx[job.member_idx]
      return {
        ...job,
        memberName: adv?.name,
        memberEmail: adv?.email,
      }
    }
    return job
  })
})

export default reactive({
  items,
})
