import router from '@/router'

export const syncronizeParamsWithUrl = () => {
  const routerQuery = router.history.current.query
  return Object
    .keys(routerQuery)
    .reduce((dict, key) => {
      if (['page', 'size', 'total'].includes(key)) {
        return dict
      }
      if (key === null || key === '') {
        return dict
      }
      const value = routerQuery[key]
      if (typeof value === 'string') {
        return {
          ...dict,
          [key]: [value],
        }
      }
      return {
        ...dict,
        [key]: routerQuery[key],
      }
    }, {})
}

export const replaceRouterQuery = params => {
  router.replace({
    query: { ...params },
  }).catch(() => { })
}
