import {
  computed,
} from '@vue/composition-api'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'
import {
  values,
} from '@/utils/value'

import state from './state'
import api from './api'

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    {
      label: 'IDX',
      paramName: 'idx',
      format: 'number',
    },
    {
      label: 'CREATED_AT',
      paramName: 'created_at',
      format: 'date',
    },
    {
      label: 'STATE',
      paramName: 'state',
      format: 'checkbox',
      options: [
        {
          text: 'SUCCESS',
          value: 'SUCCESS',
        },
        {
          text: 'FAIL',
          value: 'FAIL',
        },
        {
          text: 'MISSING_SEED',
          value: 'MISSING_SEED',
        },
      ],
    },
  ]

  const fetchInfRecommendJobList = () => api.fetchInfRecommendJobList({
    ...searchParams.value,
    sort_by: state.sortBy,
  }).then(response => {
    const resData = response.data
    state.infRecommendJobList = resData.data
    state.totalRows = resData.page_info.total_count
    state.filterParams = {
      ...state.filterParams,
      total: resData.page_info.total_count,
    }
  })

  const searchMemberList = () => api.searchMemberList({
    adm_idx_list: values(state.infRecommendJobList.filter(e => e.member_type === 'ADM'), 'member_idx'),
    adv_idx_list: values(state.infRecommendJobList.filter(e => e.member_type === 'ADV'), 'member_idx'),
  }).then(response => {
    state.admList = response.data.data.adm_list
    state.advList = response.data.data.adv_list
  })

  const fetchItems = () => {
    state.isBusy = true
    fetchInfRecommendJobList()
      .then(() => searchMemberList())
      .then(() => {
        replaceRouterQuery(searchParams.value)
      })
      .catch(() => {
        makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      })
      .finally(() => {
        state.isBusy = false
      })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    fetchInfRecommendJobList()
  }

  const tableColumns = [
    { key: 'idx', label: 'idx', sortable: true },
    { key: 'member_type', label: 'member_type' },
    { key: 'member', label: 'member' },
    { key: 'channel', label: 'channel' },
    { key: 'seed_type', label: 'seed_type' },
    { key: 'seed', label: 'seed' },
    { key: 'state', label: 'state' },
    { key: 'created_at', label: 'created_at' },
  ]

  const changePage = params => {
    state.currentPage = params.page
    fetchItems()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    fetchItems()
  }

  return {
    categoryOptions,
    tableColumns,
    fetchItems,
    search,
    changePage,
    changeSort,
  }
}
