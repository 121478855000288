import {
  syncronizeParamsWithUrl,
} from '@/utils/syncUrl'
import router from '@/router'
import state from './state'

export default () => {
  const initialize = () => {
    const routerQuery = router.history.current.query

    state.currentPage = parseInt(routerQuery.page, 10) || 1
    state.totalRows = parseInt(routerQuery.total, 10) || 1
    state.perPage = parseInt(routerQuery.size, 10) || 20

    const params = syncronizeParamsWithUrl()
    state.filterParams = params
  }

  return {
    initialize,
  }
}
