<template>
  <BCard class="p-100">
    <BCardHeader>
      <BRow>
        <BCol>
          <div class="pb-1">
            <slot name="shortcut" />
          </div>
          <div>
            <h2 class="pb-50">
              검색 필터
            </h2>
          </div>
        </BCol>
      </BRow>
    </BCardHeader>
    <BCardBody>
      <slot name="body" />
      <BRow>
        <BCol>
          <BFormGroup label="카테고리별 검색">
            <BRow>
              <BCol
                cols="12"
                :xl="leftWidth"
              >
                <VSelect
                  v-model="categorySelected"
                  :options="categoryOptions"
                  placeholder="검색 항목 선택"
                  @input="syncCheckedValue"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </VSelect>
              </BCol>
              <BCol
                v-if="categoryFormat === 'date'"
                cols="12"
                :xl="rightWidth"
              >
                <BInputGroup>
                  <TagbyDateRangeInputV2
                    v-model="dateValue"
                  />
                  <BInputGroupAppend>
                    <BButton
                      variant="outline-primary"
                      @click="addParam"
                    >
                      Add
                    </BButton>
                  </BInputGroupAppend>
                </BInputGroup>
              </BCol>
              <BCol
                v-else-if="categoryFormat === 'checkbox'"
                cols="12"
                :xl="12 - leftWidth"
                class="d-flex align-items-center"
              >
                <BFormCheckboxGroup
                  :checked="checkboxValue"
                  :options="checkboxOptions"
                  @input="inputCheckbox"
                />
              </BCol>
              <BCol
                v-else-if="categoryFormat === 'range'"
                cols="12"
                :xl="12 - leftWidth"
                class="d-flex align-items-center"
              >
                <BInputGroup>
                  <TagbyRangeInputV2
                    v-model="rangeValue"
                    @keyup.enter="addParam"
                    @keydown.enter="keydownEnter"
                  />
                  <BInputGroupAppend>
                    <BButton
                      variant="outline-primary"
                      @click="addParam"
                    >
                      Add
                    </BButton>
                  </BInputGroupAppend>
                </BInputGroup>
              </BCol>
              <BCol
                v-else-if="categoryFormat === 'number'"
                cols="12"
                :xl="rightWidth"
              >
                <BInputGroup>
                  <BFormInput
                    v-model="textValue"
                    class="d-inline-block"
                    placeholder="검색 텍스트 입력"
                    number
                    type="number"
                    @keyup.enter="addParam"
                    @keydown.enter="keydownEnter"
                  />
                  <BInputGroupAppend>
                    <BButton
                      variant="outline-primary"
                      @click="addParam"
                    >
                      Add
                    </BButton>
                  </BInputGroupAppend>
                </BInputGroup>
              </BCol>
              <BCol
                v-else
                cols="12"
                :xl="rightWidth"
              >
                <BInputGroup>
                  <BFormInput
                    v-model="textValue"
                    class="d-inline-block"
                    placeholder="검색 텍스트 입력"
                    @keyup.enter="addParam"
                    @keydown.enter="keydownEnter"
                  />
                  <BInputGroupAppend>
                    <BButton
                      variant="outline-primary"
                      @click="addParam"
                    >
                      Add
                    </BButton>
                  </BInputGroupAppend>
                </BInputGroup>
              </BCol>
            </BRow>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormTags
            :value="tags"
            input-id="tags-basic"
            class="mb-2"
            :tag-validator="() => false"
            invalid-tag-text="이곳에 직접 입력할 수 없습니다"
            placeholder="위에서 입력한 값을 보여줍니다"
            @input="removeParam"
          />
        </BCol>
      </BRow>
      <BRow>
        <BCol class="d-flex">
          <BButton
            variant="gradient-primary"
            class="mr-1"
            @click="search"
          >
            검색
          </BButton>
          <slot name="buttons" />
        </BCol>
      </BRow>
    </BCardBody>
  </BCard>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue"
import {
  computed,
  defineComponent,
  ref,
} from "@vue/composition-api"
import VSelect from "vue-select"
import FlatPickr from "vue-flatpickr-component"
import TagbyRangeInputV2 from "@/components/TagbyRangeInputV2.vue"
import TagbyDateRangeInputV2 from "@/components/TagbyDateRangeInputV2.vue"
import useResolveValue from "@/utils/resolveValue"

export default defineComponent({
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    VSelect,
    FlatPickr,
    TagbyRangeInputV2,
    TagbyDateRangeInputV2,
  },
  props: {
    categoryOptions: {
      type: Array,
      required: true,
    },
    leftWidth: {
      type: Number,
      default: 2,
    },
    rightWidth: {
      type: Number,
      default: 4,
    },
    initialParams: {
      type: Object,
      required: true,
    },
  },
  emits: ["search"],
  setup(props, { emit }) {
    const { resolveDate } = useResolveValue()
    const categorySelected = ref(props.categoryOptions[0])
    const categoryLabel = computed(() => categorySelected.value?.label)
    const checkboxOptions = computed(() => categorySelected.value?.options)
    const categoryFormat = computed(() => categorySelected.value?.format)
    const textValue = ref()
    const rangeValue = ref([null, null])
    const dateValue = ref([null, null])
    const checkboxValue = ref([])
    const getKey = (format, value, initLabel = null) => {
      const label = initLabel ?? categoryLabel.value
      if (format === "date") {
        const [startAt, endAt] = value.split("~")
        const startDate = resolveDate(startAt)
        const endDate = resolveDate(endAt)
        return `${label}:${startDate}~${endDate}`
      }
      if (format === "checkbox") {
        return `${label}:${value}`
      }
      if (format === "range") {
        const [prependValue, appendValue] = value.split("~")
        const prependNumber = parseInt(prependValue, 10)
        const appendNumber = parseInt(appendValue, 10)
        return `${label}:${prependNumber ?? ""}~${appendNumber ?? ""}`
      }
      return `${label}:${value}`
    }
    const initParamsQueue = computed(() => Object
      .keys(props.initialParams)
      .filter(key => {
        const values = props.initialParams[key]
        return values instanceof Array
      })
      .map(key => {
        const values = props.initialParams[key]
        return values.map(value => ({
          key,
          value,
        }))
      })
      .reduce((p, c) => [...p, ...c], [])
      .map(keyValue => {
        const option = props.categoryOptions.find(o => o.paramName === keyValue.key)
        return {
          key: getKey(option.format, keyValue.value, option.label),
          label: option.label,
          name: option.paramName,
          format: option.format,
          value: keyValue.value,
        }
      }))
    const addParamsQueue = ref([])
    const removeParamQueue = ref([])
    const filterParams = computed(() => [...initParamsQueue.value, ...addParamsQueue.value].filter(e => !removeParamQueue.value.map(r => r.key).includes(e.key)))
    const lastCheckedValue = computed(() => {
      const { length } = checkboxValue.value
      return checkboxValue.value[length - 1]
    })
    const getCheckedText = value => checkboxOptions.value?.find(option => option.value === value)?.text
    const lastCheckedText = computed(() => getCheckedText(lastCheckedValue.value))
    const tags = computed(() => filterParams.value.map(param => param.key))

    const syncCheckedValue = () => {
      const currentCategoryParams = filterParams.value.filter(param => param.name === categorySelected.value.paramName)
      checkboxValue.value = currentCategoryParams.map(param => param.value)
    }

    const addParam = () => {
      if (categoryFormat.value === "date") {
        if (dateValue.value?.[0] == null) return
        const value = `${dateValue.value[0]}~${dateValue.value[1]}`
        addParamsQueue.value = [
          ...addParamsQueue.value,
          {
            key: getKey(categoryFormat.value, value),
            label: categorySelected.value.label,
            name: categorySelected.value.paramName,
            value,
          },
        ]
        dateValue.value = null
      } else if (categoryFormat.value === "checkbox") {
        addParamsQueue.value = [
          ...addParamsQueue.value,
          {
            key: getKey(categoryFormat.value, lastCheckedText.value),
            label: categorySelected.value.label,
            name: categorySelected.value.paramName,
            format: "checkbox",
            value: lastCheckedValue.value,
          },
        ]
        syncCheckedValue()
      } else if (categoryFormat.value === "range") {
        if (rangeValue.value?.[0] == null && rangeValue.value?.[1] == null) return
        const value = `${rangeValue.value[0]}~${rangeValue.value[1]}`
        addParamsQueue.value = [
          ...addParamsQueue.value,
          {
            key: getKey(categoryFormat.value, value),
            label: categorySelected.value.label,
            name: categorySelected.value.paramName,
            format: "range",
            value,
          },
        ]
        rangeValue.value = null
      } else {
        if (textValue.value == null) return
        addParamsQueue.value = [
          ...addParamsQueue.value,
          {
            key: getKey(categoryFormat.value, textValue.value),
            label: categorySelected.value.label,
            name: categorySelected.value.paramName,
            value: textValue.value,
          },
        ]
        textValue.value = null
      }
    }

    const removeParam = tagList => {
      removeParamQueue.value = [...initParamsQueue.value, ...addParamsQueue.value].filter(e => !tagList.includes(e.key))
      syncCheckedValue()
    }

    // const syncInitialParams = () => {
    //   const parsedInitialParams = Object
    //     .keys(props.initialParams)
    //     .map(key => {
    //       const values = props.initialParams[key]
    //       return values.map(value => ({
    //         key,
    //         value,
    //       }))
    //     })
    //     .reduce((p, c) => [...p, ...c], [])
    //     .map(keyValue => {
    //       const option = props.categoryOptions.find(o => o.paramName === keyValue.key)
    //       return {
    //         key: getKey(option.format, keyValue.value, option.label),
    //         label: option.label,
    //         name: option.paramName,
    //         format: option.format,
    //         value: keyValue.value,
    //       }
    //     })
    //   initParamsQueue.value = parsedInitialParams
    // }
    syncCheckedValue()
    // syncInitialParams()

    const inputCheckbox = checkedValue => {
      if (checkboxValue.value.length < checkedValue.length) {
        checkboxValue.value = checkedValue
        addParam()
      } else {
        const removedCheckboxValue = checkboxValue.value.find(value => !checkedValue.includes(value))
        const text = getCheckedText(removedCheckboxValue)
        const key = `${categoryLabel.value}:${text}`
        addParamsQueue.value = addParamsQueue.value.filter(param => param.key !== key)
        checkboxValue.value = checkedValue
      }
    }

    const search = () => {
      const makedFilterParams = filterParams.value.reduce((p, c) => {
        const values = p[c.name] ?? []
        return {
          ...p,
          [c.name]: [...values, c.value],
        }
      }, {})
      emit("search", makedFilterParams)
      addParamsQueue.value = []
      removeParamQueue.value = []
    }

    const keydownEnter = e => {
      if (e.metaKey || e.ctrlKey) {
        addParam()
        search()
      }
    }

    return {
      categorySelected,
      checkboxOptions,
      categoryFormat,
      textValue,
      rangeValue,
      dateValue,
      checkboxValue,
      tags,
      addParam,
      removeParam,
      search,
      keydownEnter,
      inputCheckbox,
      syncCheckedValue,
      // syncInitialParams,
    }
  },
})

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
