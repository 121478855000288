<template>
  <b-table
    ref="refUserListTable"
    class="position-relative"
    primary-key="idx"
    show-empty
    empty-text="No matching records found"
    :items="items"
    :fields="fields"
    small
    responsive
    :busy="busy"
    :selectable="selectable"
    :hover="hover"
    no-local-sorting
    sort-direction="desc"
    @row-selected="$emit('row-selected', $event)"
    @sort-changed="$emit('sort-changed', $event)"
  >
    <template #table-busy>
      <div class="text-center">
        <b-spinner
          variant="primary"
          label="API Loading..."
        />
        <div>
          <strong>Loading...</strong>
        </div>
      </div>
    </template>

    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>

  </b-table>
</template>

<script>
import { ref } from '@vue/composition-api'

import {
  BTable,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BSpinner,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['row-selected'],
  setup() {
    const refUserListTable = ref()
    return {
      refUserListTable,
    }
  },
}
</script>
