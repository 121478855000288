<template>
  <b-card
    no-body
    class="mb-0"
  >
    <tagby-table
      ref="table"
      :items="items"
      :fields="fields"
      :busy="busy"
      :selectable="selectable"
      :hover="hover"
      @row-selected="$emit('row-selected', $event)"
      @sort-changed="$emit('sort-changed', $event)"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </tagby-table>
    <tagby-pagination
      :currentPage="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @change="$emit('page-change', {page: $event})"
    />
  </b-card>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

import {
  BCard,
} from 'bootstrap-vue'
import TagbyTable from './TagbyTable.vue'
import TagbyPagination from './TagbyPagination.vue'

export default defineComponent({
  components: {
    BCard,
    TagbyTable,
    TagbyPagination,
  },
  emits: ['page-change', 'row-selected'],
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    fields: {
      type: Array,
      default: () => ([]),
    },
    busy: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const table = ref()
    return {
      table,
    }
  },
})
</script>
