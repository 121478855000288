export const values = (data, key) => data.map(e => e[key])

export const indexing = (data, key) => data.reduce((p, c) => ({ ...p, [c[key]]: c }), {})

export const grouping = (data, key) => data.reduce((p, c) => ({
  ...p,
  [c[key]]: [...(p[c[key]] ?? []), c],
}), {})

export const all = data => data.reduce((p, c) => p && c, true)

export const any = data => data.reduce((p, c) => p || c, false)

export const bool = data => {
  if (data == null) {
    return false
  }
  if (data instanceof Array) {
    return data.length > 0
  }
  if (data instanceof Number) {
    return data !== 0
  }
  if (data instanceof String) {
    return data != ""
  }
  return Boolean(data)
}
